// If you want to override variables do it here
// @import "variables";
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// If you want to add something do it here

@import "custom";

// ie fixes
@import "ie-fix";

// Body Sections css **********************************

// body {
//   margin: 0;
//   font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
//     sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
//     "Noto Color Emoji";
//   // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
//   // "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
//   // "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//   // font-size: 0.875rem;
//   font-size: 1.4rem;
//   // font-weight: 500;
//   line-height: 1.5;
//   color: #23282c;
//   text-align: left;
// }

.borderTopDiv{
  border-top:2px solid $blueDark;
}


.titleNameLink {
  font-weight: 600;
  color: black;
  min-width: 200px;
}

#search-field,
#page-menu {
  // display: block;
  display: inline-block;
  // width: 100%;
  width: auto;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin-left: 5px;
  margin-bottom: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  // color: #5c6873;
  color: #5c6873;
  // background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input[type="search"] {
  box-sizing: border-box;
}

// pagination

html:not([dir="rtl"]) .pagination {
  // padding-left: 0;
}
.col-xs-8 {
  width: 66.66666667%;
  float: right;
}
.pagination {
  display: flex;
  list-style: none;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
  // margin: 0;
}
// .pull-right {
//   float: right !important;
// }
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 5% -16% 0 0;
  border-radius: 4px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.pagination > li {
  display: inline;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

a {
  //color: #031475;
  color: $blueDark;
  text-decoration: none;
  background-color: transparent;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  //background-color: #337ab7;
  background-color: $blueDark;
  // border-color: #337ab7;
  border-color: $blueDark;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-angle-double-left:before {
  content: "\f100";
}

// Domastic Dashboard css

// ***********************************************

.titleName {
  color: $blueDark;
}
.titleNameRprt {
  color: $blueDark;
}
.titleNameRprt:hover {
  color: $info;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imageSize {
  // width: 100%;
  height: 30px;
}

.BgColorCardsgray {
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
  color: white;
  border-radius: 10px;
}

.PopupBgCOlor {
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
  color: white;
  border-radius: 0px;
}

.BgColorCards {
  // background: linear-gradient(
  //   132deg,
  //   rgba(2, 0, 36, 0.8) 0%,
  //   rgba(9, 9, 121, 0.8) 35%,
  //   rgba(0, 255, 209, 0.8) 100%
  // );
  //   background: linear-gradient(
  //     138deg,
  //     rgba(11, 54, 105, 1) 30%,
  //     rgb(110, 110, 110) 100%
  //   );
  color: $blueDark;
  background-color: #fafafa;
  border-left: 3px solid $blueDark;
  border-right: 3px solid $gray-200;
  border-top: 0;
  border-bottom: 0;
  border-radius: 10px;
  background: linear-gradient(
    138deg,
    rgba(7, 43, 87, 1) 0%,
    rgba(74, 30, 73, 1) 74%,
    rgba(152, 74, 88, 1) 100%
  );
}

// error message Color

.errorColor {
  color: red;
  font-size: 85%;
}

// Drag and Drop Css

.board {
  display: inline-flexbox;
  width: 100%;
  min-height: 100px;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  // background-color: rgb(233, 233, 233);
  // padding: 1%;
}
.card {
  // padding: 1%;
  display: inline-flexbox;
  width: 100%;
  cursor: pointer;
  // background-color: royalblue;
  // color: black;
}

// React Table css
.table {
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: 0.3%;
  margin-bottom: 3%;

  thead,
  th,
  tr,
  td {
    border-left: none;
    border-right: none;
    border-color: rgb(226, 226, 226);
  }

  tr td {
    line-height: 1;
  }

  tr:hover {
    background-color: #f0f3f5;
  }
}

.table thead {
  // text-align: center;
  cursor: pointer;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f1f3f6;
  color: $blueDark;
  border-top: 2px solid $blueDark;
  // border-bottom: 2px solid $blueDark;
  // background-color: $blueDark;
  // color: $white;
  width: 100%;
  margin-top: 10%;
}
.table .empty-cell {
  font-style: italic;
  color: darkgray;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

th:last-child,
td:last-child,
th:nth-last-child(1),
td:nth-last-child(1) {
  text-align: center;
  width: 100px;
}

.sort-icon:after {
 
  color: rgb(168, 167, 167);
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sort-icon.sort-ascending:after {
  content: "\f0de";
}
.sort-icon.sort-descending:after {
  content: "\f0dd";
}
.sort-icon.sort-none:after {
  content: "\f0dc";
}

// .Appointments {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 212, 255, 1) 100%
//   );
// }

// .Doctors {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 255, 98, 1) 100%
//   );
// }
// .Hospitals {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(255, 81, 0, 1) 100%
//   );
// }
// .Clinics {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(255, 0, 215, 1) 100%
//   );
// }
// .Labs {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(254, 255, 0, 1) 100%
//   );
// }
// .Pharmacy {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 255, 218, 1) 100%
//   );
// }
// .BloodBank {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(86, 255, 0, 1) 100%
//   );
// }
// .FitnessCenter {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 121, 255, 1) 100%
//   );
// }
// .CenterOfExcellence {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(86, 255, 0, 1) 100%
//   );
// }
// .Ambulance {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(255, 0, 232, 1) 100%
//   );
// }
// .OrganSwap {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(254, 255, 0, 1) 100%
//   );
// }
// .Hotels {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(0, 255, 209, 1) 100%
//   );
// }
// .Transport {
//   background: linear-gradient(
//     132deg,
//     rgba(2, 0, 36, 1) 0%,
//     rgba(9, 9, 121, 1) 35%,
//     rgba(255, 4, 0, 1) 100%
//   );
// }

// socail medial icons colors

.facebook {
  color: $facebook;
}

.linkedIn {
  color: $linkedin;
}
.twitter {
  color: $twitter;
}
.instagram {
  color: $instagram;
}
.skype {
  color: $skype;
}
.website {
  color: $reddit;
}
.youtube {
  color: $youtube;
}
.blog {
  color: $vine;
}
.WhatsApp {
  color: $foursquare;
}
.vimeo {
  color: $vimeo;
}
.Interested {
  color: $reddit;
}
.Print {
  color: $facebook;
}
.Associations {
  color: $twitter;
}
