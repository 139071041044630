// // Here you can add other styles

@import "variables";

// Header Sections css **********************************
.app-header {
  position: relative;
  flex-direction: row;
  height: $navbar-height;
  padding: 0;
  margin: 0;
  background-color: $navbar-bg;
  @include borders($navbar-border);

  .navbar-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $navbar-brand-width;
    height: $navbar-height;
    padding: 0;
    margin-right: 0;
    background-color: $navbar-brand-bg;
    @include borders($navbar-brand-border);

    .navbar-brand-minimized {
      display: none;
    }
  }

  .navbar-toggler {
    min-width: 50px;
    padding: $navbar-toggler-padding-y 0;

    &:hover .navbar-toggler-icon {
      background-image: $navbar-toggler-icon-hover;
    }
  }

  .navbar-toggler-icon {
    height: 23px;
    background-image: $navbar-toggler-icon;
  }

  .navbar-nav {
    flex-direction: row;
    align-items: center;
  }

  .nav-item {
    position: relative;
    min-width: 50px;
    margin: 0;
    text-align: center;

    button {
      margin: 0 auto;
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      background: 0;
      border: 0;

      &:active {
        color: #031475;
        font-weight: bold;
      }

      .badge {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -16px;
        margin-left: 0;
      }

      > .img-avatar {
        height: $navbar-height - 20px;
        margin: 0 10px;
      }
    }
  }

  .dropdown-menu {
    padding-bottom: 0;
    line-height: $line-height-base;
  }

  .dropdown-item {
    min-width: 180px;

    &:active {
      background-color: white;
      color: $blueDark;
      font-weight: bold;
      
    }
  }
}

// comment start

// .navbar-brand {
//   color: $navbar-active-color;

//   @include hover-focus {
//     color: $navbar-active-color;
//   }
// }
// end

// header navitem active color
.navbar-nav {
  .nav-link {
    //     color: $navbar-color;
    color: $blueDark;

    @include hover-focus {
      color: $navbar-hover-color;
    }
  }

  .open > .nav-link,
  .active > .nav-link,
  .nav-link.open,
  .nav-link.active {
    @include plain-hover-focus {
      color: $navbar-active-color;
    }
  }
}

.navbar-divider {
  background-color: rgba(0, 0, 0, 0.075);
}

@include media-breakpoint-up($breakpoint-mobile-up) {
  .brand-minimized {
    .app-header {
      .navbar-brand {
        width: $navbar-brand-minimized-width;
        background-color: $navbar-brand-minimized-bg;
        @include borders($navbar-brand-minimized-border);

        .navbar-brand-full {
          display: none;
        }

        .navbar-brand-minimized {
          display: block;
        }
      }
    }
  }
}

// Nav Menu Side Sections css **********************************

// .AppSidebar {
//   background: #f8f8f8;
//   color: #333333;
// }

// Aside Sections css **********************************

.aside-menu {
  z-index: $zindex-sticky - 1;
  width: $aside-menu-width;
  color: $aside-menu-color;
  background: $aside-menu-bg;
  @include borders($aside-menu-borders);

  .nav-tabs {
    border-color: $border-color;
    .nav-link {
      padding: $aside-menu-nav-padding-y $aside-menu-nav-padding-x;
      color: $body-color;
      border-top: 0;
      @include border-radius(0);
      &.active {
        color: theme-color("primary");
        border-right-color: $border-color;
        border-left-color: $border-color;
      }
    }
    .nav-item:first-child {
      .nav-link {
        border-left: 0;
      }
    }
  }

  .tab-content {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    border-top: 1px solid $border-color;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
      width: 10px;
      margin-left: -10px;
      appearance: none;
    }

    // &::-webkit-scrollbar-button {    }

    &::-webkit-scrollbar-track {
      background-color: lighten($aside-menu-bg, 5%);
      border-right: 1px solid darken($aside-menu-bg, 5%);
      border-left: 1px solid darken($aside-menu-bg, 5%);
    }

    // &::-webkit-scrollbar-track-piece {    }

    &::-webkit-scrollbar-thumb {
      height: 50px;
      background-color: darken($aside-menu-bg, 10%);
      background-clip: content-box;
      border-color: transparent;
      border-style: solid;
      border-width: 1px 2px;
    }

    .tab-pane {
      padding: 0;
    }
  }
}
