// Color system
// stylelint-disable

$Site-bg-color: #f1f3f6;

$white: #fff !default;
$gray-base: #181b1e !default;
$gray-100: #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-150: #e8ebee !default; // lighten($gray-base, 80%);
$gray-200: #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300: #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400: #acb4bc !default; // lighten($gray-base, 60%);
$gray-500: #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600: #73818f !default; // lighten($gray-base, 40%);
$gray-700: #5c6873 !default; // lighten($gray-base, 30%);
// $red-800: #ff0000 !default; // lighten($gray-base, 10%);
$gray-800: #2f353a !default; // lighten($gray-base, 10%);
$gray-900: #23282c !default; // lighten($gray-base, 5%);
$black: #000 !default;
$gray-1000: #e5e5e5;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #20a8d8 !default;
$blueDark: #031475 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee0505 !default;
// $red: #f86c6b !default;
$orange: #f8cb00 !default;
$yellow: #ffc107 !default;
$green: #4dbd74 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$light-blue: #63c2de !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "light-blue": $light-blue,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: $blueDark !default;
// $primary: $blue !default;
$secondary: $gray-300 !default;
$success: $green !default;
$info: $light-blue !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// Brand Colors

$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$google-plus: #d34836 !default;
$flickr: #ff0084 !default;
$tumblr: #32506d !default;
$xing: #026466 !default;
$github: #4183c4 !default;
$html5: #e34f26 !default;
$openid: #f78c40 !default;
$stack-overflow: #fe7a15 !default;
$youtube: #b00 !default;
$css3: #0170ba !default;
$dribbble: #ea4c89 !default;
$google-plus: #bb4b39 !default;
$instagram: #517fa4 !default;
$pinterest: #cb2027 !default;
$vk: #45668e !default;
$yahoo: #400191 !default;
$behance: #1769ff !default;
$skype: #1769ff !default;
$dropbox: #007ee5 !default;
$reddit: #ff4500 !default;
$spotify: #7ab800 !default;
$vine: #00bf8f !default;
$foursquare: #1073af !default;
$vimeo: #aad450 !default;

$brands-colors: () !default;
$brands-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "linkedin": $linkedin,
    "google-plus": $google-plus,
    "flickr": $flickr,
    "tumblr": $tumblr,
    "xing": $xing,
    "github": $github,
    "html5": $html5,
    "openid": $openid,
    "stack-overflow": $stack-overflow,
    "youtube": $youtube,
    "css3": $css3,
    "dribbble": $dribbble,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "vk": $vk,
    "yahoo": $yahoo,
    "behance": $behance,
    "dropbox": $dropbox,
    "reddit": $reddit,
    "spotify": $spotify,
    "vine": $vine,
    "foursquare": $foursquare,
    "vimeo": $vimeo,
  ),
  $brands-colors
);

// ***********************************************
// node_modules\@coreui\coreui\scss\_variables.scss

// Import CoreUI default colors
// @import "variables/colors";

// Customized Bootstrap variables
// @import "variables/bootstrap/variables";

// Import Bootstrap variables after customization for use below
// @import "bootstrap/functions"; // from bootstrap node_modules
@import "../../node_modules/@coreui/coreui/scss/bootstrap/functions";
// @import "bootstrap/variables";
// from bootstrap node_modules
@import "../../node_modules/@coreui/coreui/scss/bootstrap";
// CoreUI Variables
// stylelint-disable
$enable-sidebar-nav-rounded: false !default;
$layout-transition-speed: 0.25s !default;

// Navbar

$navbar-height: 55px !default;
$navbar-bg: $Site-bg-color;
// $navbar-bg: #fff !default;
$navbar-border: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;
$navbar-brand-width: 155px !default;
$navbar-brand-bg: transparent !default;
$navbar-brand-border: 0 !default;

$navbar-brand-minimized-width: 50px !default;
$navbar-brand-minimized-bg: $navbar-brand-bg !default;
$navbar-brand-minimized-border: $navbar-brand-border !default;

$navbar-color: $gray-900 !default;
$navbar-hover-color: $gray-800 !default;
$navbar-active-color: $gray-800 !default;
$navbar-disabled-color: $gray-300 !default;

$navbar-toggler-icon: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$navbar-toggler-icon-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-hover-color}' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Sidebar

$sidebar-width: 200px !default;
$sidebar-padding: 0 !default;
$sidebar-minimized-width: 50px !default;
$sidebar-minimized-height: $sidebar-minimized-width !default;
$sidebar-compact-width: 150px !default;
$sidebar-compact-height: $sidebar-compact-width !default;
// menu settings
// $sidebar-color: #fff !default;
$sidebar-bg: $gray-200;
$sidebar-color: $gray-300;
// $sidebar-color: $gray-900 !default;
// $sidebar-bg: $Site-bg-color;
$sidebar-borders: none !default;
$mobile-sidebar-width: 220px !default;

// Sidebar Header

$sidebar-header-height: auto !default;
// $sidebar-header-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-header-bg: $gray-900;
$sidebar-header-padding-y: 0.75rem !default;
$sidebar-header-padding-x: 1rem !default;

// Sidebar Form

$sidebar-form-border: 0 !default;
$sidebar-form-bg: darken($sidebar-bg, 10%) !default;
$sidebar-form-color: #fff !default;
$sidebar-form-placeholder-color: rgba(255, 255, 255, 0.7) !default;

// Sidebar Navigation

// $sidebar-nav-color: #fff !default;
// $sidebar-nav-color: $gray-900;
$sidebar-nav-color: $blueDark;
$sidebar-nav-title-padding-y: 0.75rem !default;
$sidebar-nav-title-padding-x: 1rem !default;
$sidebar-nav-title-color: $gray-200 !default;
$sidebar-nav-link-padding-y: 0.75rem !default;
$sidebar-nav-link-padding-x: 1rem !default;
// $sidebar-nav-link-color: #fff !default;
// $sidebar-nav-link-color: $gray-900;
$sidebar-nav-link-color: $gray-900;
$sidebar-nav-link-bg: transparent !default;
$sidebar-nav-link-icon-color: $gray-900;
$sidebar-nav-link-borders: 0 !default;

// $sidebar-nav-link-hover-color: #fff !default;
$sidebar-nav-link-hover-color: $blueDark;
// $sidebar-nav-link-hover-bg: theme-color("primary") !default;
$sidebar-nav-link-hover-bg: $gray-300;
// $sidebar-nav-link-hover-icon-color: #fff !default;
$sidebar-nav-link-hover-icon-color: $blueDark;
// $sidebar-nav-link-hover-borders: 0 !default;
$sidebar-nav-link-hover-borders-left: 5px solid $blueDark;

// $sidebar-nav-link-active-color: $red;
$sidebar-nav-link-active-color: $white;
// $sidebar-nav-link-active-color: #fff !default;
// $sidebar-nav-link-active-bg: lighten($sidebar-bg, 5%) !default;
// $sidebar-nav-link-active-bg: $gray-300;
$sidebar-nav-link-active-bg: $blueDark;
// $sidebar-nav-link-active-icon-color: theme-color("primary") !default;
// $sidebar-nav-link-active-icon-color: theme-color("primary") !default;
// $sidebar-nav-link-active-icon-color: $red;
$sidebar-nav-link-active-icon-color: $white;
// $sidebar-nav-link-active-borders: 0 !default;
// $sidebar-nav-link-active-borders: 5px solid red;
$sidebar-nav-link-active-font-Weight: bold;
.sidebar .nav-link:hover {
  background-color: $gray-300;
  color: #031475;
  border-right: 2px solid #031475;
}
.sidebar .nav-link.active {
  border-left: 4px solid #031475;
}

$sidebar-nav-link-disabled-color: darken(#fff, 30%) !default;
$sidebar-nav-link-disabled-bg: $sidebar-bg !default;
$sidebar-nav-link-disabled-icon-color: $sidebar-nav-link-icon-color !default;
$sidebar-nav-link-disabled-borders: 0 !default;

// $sidebar-nav-dropdown-color: #fff !default;
// $sidebar-nav-dropdown-color: $gray-900;
$sidebar-nav-dropdown-color: $gray-900;
.sidebar .nav-dropdown.open {
  background-color: $gray-300;
  // border-left: 2px solid red;
}
.sidebar .nav-dropdown.open .nav-link:hover {
  background-color: $gray-400;
  color: #031475;
  border-right: 2px solid #031475;
}
// .sidebar .nav-dropdown.open .nav-link:active {
//   color: red;
//   border-right: 2px solid red;
// }

.sidebar .nav-dropdown .nav-link.active {
  color: $white;
  border-left: 4px solid #031475;
}
.sidebar li a .nav-link .nav-dropdown-toggle:first-child {
  border-bottom: 3px solid #031475;
}

// dropdown child background color
// .sidebar .nav-dropdown-items {
//   background-color: $gray-400;
//   border-top: 2px solid red;
// }

// $sidebar-nav-dropdown-bg: rgba(0, 0, 0, 0.2) !default;
// $sidebar-nav-dropdown-bg: $gray-200;
$sidebar-nav-dropdown-bg: $gray-200;
$sidebar-nav-dropdown-borders: 0 !default;
// $sidebar-nav-dropdown-indicator-color: $gray-600 !default;
$sidebar-nav-dropdown-indicator-color: $gray-600 !default;
$sidebar-nav-dropdown-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$sidebar-nav-dropdown-indicator-hover-color: $sidebar-nav-link-hover-color;
$sidebar-nav-dropdown-indicator-hover: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-nav-dropdown-indicator-hover-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Sidebar Footer

$sidebar-footer-height: auto !default;
// $sidebar-footer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-footer-padding-y: 0.75rem !default;
$sidebar-footer-padding-x: 1rem !default;
$sidebar-footer-borders: 0 !default;
.sidebar .sidebar-minimizer {
  background-color: $gray-200;
  border-top: 2px solid $gray-400;
}
.sidebar .sidebar-minimizer::before {
  background-color: $gray-200;
  // border-top: -2px solid red;
}

// Sidebar Minimizer

$sidebar-minimizer-height: 50px !default;
$sidebar-minimizer-bg: rgba(0, 0, 0, 0.2) !default;
$sidebar-minimizer-borders: 0 !default;
// $sidebar-minimizer-indicator-color: $gray-600 !default;
$sidebar-minimizer-indicator-color: $gray-600 !default;
$sidebar-minimizer-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$sidebar-minimizer-hover-bg: rgba(0, 0, 0, 0.3) !default;
$sidebar-minimizer-hover-indicator-color: $sidebar-nav-link-hover-color !default;
$sidebar-minimizer-hover-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$sidebar-minimizer-hover-indicator-color}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

// Breadcrumb
$breadcrumb-borders: (
  bottom: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

// Aside

$aside-menu-width: 250px !default;
$aside-menu-color: $gray-800 !default;
$aside-menu-bg: #fff !default;
$aside-menu-borders: (
  left: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

$aside-menu-nav-padding-y: 0.75rem !default;
$aside-menu-nav-padding-x: 1rem !default;

// Footer

$footer-height: 50px !default;
$footer-bg: $gray-100 !default;
$footer-color: $body-color !default;
$footer-borders: (
  top: (
    size: 1px,
    style: solid,
    color: $border-color,
  ),
) !default;

// Avatars

$avatar-width: 36px !default;
$avatar-status-width: 10px !default;

$avatar-lg-width: 72px !default;
$avatar-lg-status-width: 12px !default;

$avatar-sm-width: 24px !default;
$avatar-sm-status-width: 8px !default;

$avatar-xs-width: 20px !default;
$avatar-xs-status-width: 8px !default;

// Cards

$card-icon-bg: transparent !default;
$card-icon-color: $body-color !default;

// Switches

$switch-width: 40px !default;
$switch-height: 26px !default;
$switch-font-size: 10px !default;

$switch-lg-width: 48px !default;
$switch-lg-height: 30px !default;
$switch-lg-font-size: 12px !default;

$switch-sm-width: 32px !default;
$switch-sm-height: 22px !default;
$switch-sm-font-size: 8px !default;

$switch-label-width: 48px !default;
$switch-label-lg-width: 56px !default;
$switch-label-sm-width: 40px !default;

$switch-handle-margin: 2px !default;

// Cursor
$cursor-disabled: default !default;

// Breakpoints for mobile
$breakpoint-mobile-up: lg !default;
$breakpoint-mobile-down: md !default;
